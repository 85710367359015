import { useEffect, useRef, useState } from 'react'

export default function useElementOffset() {
  const ref = useRef()
  const [state, setState] = useState({
    top: 0,
    bottom: 0,
  })

  useEffect(() => {
    if (!ref.current) return

    const setValues = () => {
      const top = ref.current.offsetTop

      setState({
        top,
        bottom: top + ref.current.getBoundingClientRect().height,
      })
    }

    setValues()
    window.addEventListener('resize', setValues)

    return () => {
      window.removeEventListener('resize', setValues)
    }
  }, [ref])

  return [ref, state]
}
