import React from 'react'
import {
  Flex,
  Container,
  Text,
  SimpleGrid,
  Spacer,
  Link,
  Heading,
  Skeleton,
  Button,
  Box,
} from '@chakra-ui/react'
import { useGeo, usePreorderRetailers } from '../../api/queries'
import { withErrorBoundary } from '../functionality/errorReporting'
import { BaseLink, TextLink } from '../elements/link'
import { regions } from '../../api/constants'

const ErrorScreen = () => (
  <Container maxWidth={'container.lg'} py={20}>
    <Text fontSize={'xl'} textAlign={'center'}>
      There was an error loading the list of retailers
    </Text>
  </Container>
)

const retailerPlaceholder = [{}, {}, {}, {}, {}]
const nullData = {
  retailers: {
    hardcover: retailerPlaceholder,
    ebook: retailerPlaceholder,
    audio: retailerPlaceholder,
    paperback: [],
    header: '',
    bulkorder: { stores: [{}] },
    releaseDate: '.-.-.',
  },
}

export const OrderSection = withErrorBoundary({
  FallbackComponent: ErrorScreen,
})(() => {
  const { isLoading, error, data = nullData } = usePreorderRetailers()
  const {
    geo: { region },
  } = useGeo()
  const isNorthAmerica = region === regions.northAmerica

  if (error) {
    return null
  }

  const { retailers = {} } = data

  const renderHardcover = retailers?.hardcover?.length > 0
  const renderEbook = retailers?.ebook?.length > 0
  const renderAudio = retailers?.audio?.length > 0
  const renderPaperback = retailers?.paperback?.length > 0
  const renderSubheader = retailers?.header !== ''
  const renderBulkorder = retailers?.bulkorder?.stores?.length > 0
  const renderBulkorderOld =
    !renderBulkorder && typeof retailers?.bulkorder === 'string'

  return (
    <Container id={'order'} maxWidth={'container.lg'} py={6}>
      <a id={'pre-order'} />

      <Heading
        textStyle={'heading'}
        fontSize={['36px', '56px']}
        textTransform={'uppercase'}
      >
        Available Now
      </Heading>
      <Text fontSize={'xl'} mb={8}>
        At all major book stores. {!isNorthAmerica && 'Order your copy today!'}
      </Text>

      {renderSubheader && (
        <Skeleton isLoaded={!isLoading}>
          <Text dangerouslySetInnerHTML={{ __html: retailers.header }} />
        </Skeleton>
      )}

      {isNorthAmerica && (
        <>
          <Button
            as={BaseLink}
            href={
              'https://www.ishalife.com/usa/karma-a-yogi-s-guide-to-crafting-your-destiny'
            }
          >
            Order Your Copy Today
          </Button>

          {renderBulkorder && (
            <Box mt={6}>
              <TextLink
                href={retailers.bulkorder.stores[0].url}
                style={{ textTransform: 'capitalize' }}
              >
                Bulk Order via{' '}
                {retailers.bulkorder.stores[0].retailer?.toLowerCase()}
              </TextLink>
              <Text mt={3}>{retailers.bulkorder.note}</Text>
            </Box>
          )}
        </>
      )}

      {!isNorthAmerica && (
        <>
          <Spacer mb={5} />
          <SimpleGrid
            minChildWidth={'215px'}
            spacing='40px'
            paddingBottom='20px'
          >
            {renderHardcover && (
              <RetailerGroup
                title={'hardcover'}
                retailers={retailers.hardcover}
                isLoaded={!isLoading}
              />
            )}
            {renderEbook && (
              <RetailerGroup
                title={'ebook'}
                retailers={retailers.ebook}
                isLoaded={!isLoading}
              />
            )}
            {renderAudio && (
              <RetailerGroup
                title={'audio'}
                retailers={retailers.audio}
                isLoaded={!isLoading}
              />
            )}
            {renderPaperback && (
              <RetailerGroup
                title={'paperback'}
                retailers={retailers.paperback}
                isLoaded={!isLoading}
              />
            )}
            {renderBulkorder && (
              <Skeleton isLoaded={!isLoading}>
                <RetailerGroup
                  title={'Bulk Order'}
                  retailers={retailers.bulkorder.stores}
                  isLoaded={!isLoading}
                />
                <Text mt={3}>{retailers.bulkorder.note}</Text>
              </Skeleton>
            )}

            {renderBulkorderOld && (
              <Skeleton isLoaded={!isLoading}>
                <Text
                  style={{
                    fontWeight: '500',
                    textTransform: 'uppercase',
                  }}
                >
                  Bulk Order
                </Text>
                <Text
                  style={{
                    fontWeight: '500',
                    marginTop: '10px',
                  }}
                >
                  {retailers.bulkorder}
                </Text>
              </Skeleton>
            )}
          </SimpleGrid>
        </>
      )}
    </Container>
  )
})

function RetailerLink({ url, retailer }) {
  return (
    <Link
      href={url}
      isExternal
      color={'gray.50'}
      style={{
        textDecoration: 'underline',
        textTransform: 'uppercase',
        marginTop: '10px',
      }}
      _hover={{
        color: 'blue',
      }}
    >
      {retailer}&nbsp;→
    </Link>
  )
}

const RetailerGroup = ({ title, retailers, isLoaded }) => {
  return (
    <Skeleton isLoaded={isLoaded}>
      <Flex flexDirection={'column'} alignItems={'flex-start'}>
        <Text
          style={{
            fontWeight: '500',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Text>
        {retailers.map((a) => (
          <RetailerLink key={a.url} url={a.url} retailer={a.retailer} />
        ))}
      </Flex>
    </Skeleton>
  )
}
