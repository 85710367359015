import { useTransform, useViewportScroll } from 'framer-motion'
import useElementOffset from './useElementOffset'
import { useWindowSize } from 'react-use'

const useElementScroll = ({ fromBottom = 0, fromTop = 0 } = {}) => {
  const { scrollY } = useViewportScroll()
  const [ref, { top, bottom }] = useElementOffset()
  const { height } = useWindowSize()

  const transformInitialValue = Math.max(bottom - height + fromBottom, 0)
  const transformFinalValue = top - fromTop
  const scroll = useTransform(
    scrollY,
    [transformInitialValue, transformFinalValue],
    [0, 1]
  )

  return { ref, scroll }
}

export default useElementScroll
