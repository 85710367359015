import React, { useState } from 'react'
import { Box, Heading, Image, useBreakpointValue } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import JwPlayer from '../../elements/jwPlayer'
import { useGeo } from '../../../api/queries'
import { regions } from '../../../api/constants'
import newYorkTimes from '../../../images/common/nytBestsellerSadhguru.svg'

function Video({ videoLoaded, onPlay }) {
  const file = useBreakpointValue({
    base:
      'https://player.vimeo.com/external/529179209.m3u8?s=d9608626b1aada69c25ef3b539172b85f9165c65',
    sm:
      'https://player.vimeo.com/external/529170488.m3u8?s=c77de5855b2bebb27145e368a2889a91055e7fd1',
  })

  return (
    <Box
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
      opacity={videoLoaded ? 1 : 0}
      transition={'.3s all'}
      sx={{ '&&&&& .jwplayer': { height: '100%   !important' } }}
    >
      <JwPlayer
        id={'header-video'}
        file={file}
        autoPlay={true}
        mute={true}
        repeat={true}
        showPlaceholder={false}
        controls={false}
        stretching={'fill'}
        onPlay={onPlay}
        floatOnScroll={false}
      />
    </Box>
  )
}

export function HeaderSection() {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const {
    geo: { region },
    isSuccess,
  } = useGeo()

  return (
    <Box
      id={'header-section'}
      maxHeight={'100vh'}
      position={'relative'}
      overflow={'hidden'}
      fontSize={{
        base: 'calc(16px + 3vw)',
        sm: '4vw',
        md: '3vw',
        lg: '2.8vw',
        xl: '2.4vw',
      }}
    >
      <Box display={['block', 'none']}>
        <StaticImage
          src={'../../../images/index/header/headerBackgroundMobile.png'}
          alt={'Background'}
          loading={'eager'}
          layout={'fullWidth'}
        />
      </Box>
      <Box display={['none', 'block']}>
        <StaticImage
          src={'../../../images/index/header/headerBackgroundDesktop.png'}
          alt={'Background'}
          loading={'eager'}
          layout={'fullWidth'}
        />
      </Box>

      {isSuccess && (
        <Box
          position={'absolute'}
          width={{ base: 'unset', sm: '25%', md: '33%' }}
          maxWidth={{ base: '70%', sm: 'unset' }}
          right={0}
          top={{ base: '5%', sm: 'unset' }}
          bottom={{ base: 'unset', sm: '5vh' }}
        >
          {region === regions.india ? (
            <StaticImage
              src={'../../../images/index/header/headerBookIndia.png'}
              alt={"Karma: A Yogi's Guide to Crafting Your Destiny"}
              loading={'eager'}
              placeholder={'tracedSVG'}
            />
          ) : region === regions.oceania ? (
            <StaticImage
              src={'../../../images/index/header/headerBookAustralia.png'}
              alt={"Karma: A Yogi's Guide to Crafting Your Destiny"}
              loading={'eager'}
              placeholder={'tracedSVG'}
            />
          ) : (
            <StaticImage
              src={'../../../images/index/header/headerBook.png'}
              alt={"Karma: A Yogi's Guide to Crafting Your Destiny"}
              loading={'eager'}
              placeholder={'tracedSVG'}
            />
          )}
        </Box>
      )}

      <Video onPlay={() => setVideoLoaded(true)} videoLoaded={videoLoaded} />

      <Box
        position={'absolute'}
        width={'100%'}
        bottom={0}
        textAlign={'center'}
        pb={'1em'}
        color={'white'}
      >
        <Box
          maxWidth={{ base: '80vw', sm: '40vw', md: '30vw' }}
          mx={'auto'}
          style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, .5))' }}
        >
          <Heading
            as={'h1'}
            textStyle={'heading'}
            fontSize={'2em'}
            textTransform={'uppercase'}
            textShadow={'3px 3px 11px rgba(13, 13, 36, 0.38)'}
            lineHeight={'1.2'}
          >
            Karma
            <Box
              component={'span'}
              fontSize={'.3em'}
              display={'block'}
              fontFamily={'fedraSans'}
              fontWeight={'bold'}
            >
              A Yogi's Guide To
              <br />
              Crafting Your Destiny
            </Box>
          </Heading>

          <Box fontSize={'.5em'} fontWeight={'bold'} mt={'2em'}>
            <Image
              src={newYorkTimes}
              htmlWidth={'797.49px'}
              htmlHeight={'268.91px'}
              mx={'auto'}
              maxWidth={'12em'}
              mb={'.25em'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
