import React from 'react'
import { OrderSection } from '../components/page/orderSection'
import { QuizSection } from '../components/page/index/quiz/quizSection'
import EventsSection from '../components/page/index/eventsSection/eventsSection'
import EndorsementsSection from '../components/page/index/endorsementsSection'
import Layout from '../components/layout/layout'
import { HeaderSection } from '../components/page/index/headerSection'
import { AboutBookSection } from '../components/page/index/aboutBookSection'
import metaImage from '../images/index/socialImage.jpg'
import AccoladesSection from '../components/page/index/accoladesSection'
import { IncentiveSection } from '../components/page/incentiveSection'

export default function HomePage() {
  return (
    <Layout
      fadeNav={true}
      meta={{
        title:
          'Karma Book by Sadhguru: A Yogi’s Guide to Crafting Your Destiny',
        description:
          'A new perspective on the overused and misunderstood concept of “karma” that offers the key to happiness and enlightenment, from the New York Times bestselling author and world-renowned yogi and mystic, Sadhguru.',
        image: metaImage,
      }}
      showPenguinLogo={false}
    >
      <HeaderSection />
      <AccoladesSection />
      <EventsSection />
      <AboutBookSection />
      <EndorsementsSection />
      <QuizSection />
      <OrderSection />
    </Layout>
  )
}
