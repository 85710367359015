import React, { useLayoutEffect, useRef, useState } from 'react'
import useScript from '../../hooks/useScript'
import { ErrorBoundary } from '../functionality/errorReporting'

function _JwPlayer({
  id,
  file,
  image,
  autoPlay = false,
  aspectRatio = '16:9',
  repeat = false,
  showPlaceholder = true,
  mute = false,
  style,
  onPlay,
  stretching,
  controls = true,
  floatOnScroll = true,
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  const status = useScript('https://cdn.jwplayer.com/libraries/zdPodMhD.js')
  const prefixedId = 'jw-player-container-' + id
  const isInitialized = useRef(false)

  const render = showPlaceholder || status === 'ready'

  useLayoutEffect(() => {
    if (!id) {
      console.error('JwPlayer component requires `id` prop')
    }

    if (status !== 'ready' || isInitialized.current) return

    window
      .jwplayer(prefixedId)
      .setup({
        file,
        image,
        autostart: autoPlay,
        aspectratio: aspectRatio,
        repeat,
        mute,
        controls,
        stretching,
        floating: { mode: floatOnScroll ? 'notVisible' : 'never' },
      })
      .on('ready', () => {
        setIsLoaded(true)
      })
      .on('play', () => {
        onPlay && onPlay()
      })

    isInitialized.current = true

    // We are not running cleanup function because it causes
    // scroll position to persist on page navigation in safari
    // so you see the middle of the page instead of the top
  }, [id, prefixedId, status, file, autoPlay, aspectRatio, image, onPlay])

  return render ? (
    <div
      id={prefixedId}
      // Show black box as a placeholder
      style={{
        ...(!isLoaded && {
          paddingBottom: '56.25%',
          backgroundColor: 'black',
        }),
        ...style,
      }}
    />
  ) : null
}

export default function JwPlayer(props) {
  return (
    <ErrorBoundary>
      <_JwPlayer {...props} />
    </ErrorBoundary>
  )
}
