import React, { useEffect, useRef, useState } from 'react'
import { Container, Img, useMergeRefs } from '@chakra-ui/react'
import nyt from '../../../images/index/accolades/nyt.png'
import amazon from '../../../images/index/accolades/amazon.png'
import booknet from '../../../images/index/accolades/booknet.png'
import globe from '../../../images/index/accolades/globe.png'
import nielsen from '../../../images/index/accolades/nielsen.png'
import usa from '../../../images/index/accolades/usa.png'
import MotionBox from '../../elements/motionBox'
import { useTransform } from 'framer-motion'
import { useMeasure } from 'react-use'
import useElementScroll from '../../../hooks/useElementScroll'

export default function AccoladesSection() {
  const [measureRef, { width }] = useMeasure()
  const accoladesRef = useRef()
  const mergedRef = useMergeRefs(measureRef, accoladesRef)

  const [scrollDistance, setScrollDistance] = useState(0)
  const { ref: containerRef, scroll } = useElementScroll({
    fromBottom: 20,
    fromTop: 100,
  })
  const x = useTransform(scroll, [0, 1], [0, -scrollDistance])

  useEffect(() => {
    if (accoladesRef.current) {
      const hiddenContentLength = accoladesRef.current.scrollWidth - width
      setScrollDistance(Math.max(0, hiddenContentLength))
    }
  }, [width])

  return (
    <Container
      maxW={false}
      py={6}
      overflow={'hidden'}
      textAlign={'center'}
      ref={containerRef}
    >
      <MotionBox
        display={'inline-flex'}
        height={['40px', '50px']}
        justifyContent={'flex-start'}
        mx={'auto'}
        ref={mergedRef}
        style={{ x }}
      >
        {[
          { src: amazon },
          { src: nyt },
          { src: usa },
          { src: nielsen },
          { src: globe },
          { src: booknet },
        ].map((props, index) => (
          <Img {...props} height={'100%'} key={index} mx={[2, 6]} />
        ))}
      </MotionBox>
    </Container>
  )
}
